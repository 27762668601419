import '../styles/bubbleprotects.scss';

import loadable from '@loadable/component';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Rellax from 'rellax';
import ScrollOut from 'scroll-out';
import Swal from 'sweetalert2';

// import tophalfleft from '../images/lthalftop.svg';
// import bthalfleft from '../images/lthalfbt.svg';
// import tophalfrt from '../images/rthalftop.svg';
// import bthalfrt from '../images/rthalfbt.svg';
// import smalltoplt from '../images/smalltoplt.svg';
// import bigbtleft from '../images/bigbtleft.svg';
// import medtoprt from '../images/medtoprt.svg';
// import smallbtrt from '../images/smallbtrt.svg';
// import mobcircleimg from '../images/mobcircleimg.svg';
import FaqSection from '../components/faq';
import Layout from '../components/layout';
import PartnerLogo from '../components/PartnerLogos';
import SEO from '../components/seo';
import hey from '../images/heynew.svg';
// import homeinsurance from '../images/Home-Insurance1.png';
// import lifeinsurance from '../images/life-Insurance1.png';
// import tick from '../images/images/icon-tick-green.svg';
import images from '../images/images/education2.png';
// import ExitIntent from '../components/ExitIntent';
// import home_insurance from '../images/home_insurance.svg';
// import life_insurance from '../images/life_insurance.svg';
import waiting from '../images/waitingnew.svg';


const LandingCalculator = loadable( () =>  import( './landingcalculator' ) );
const VerticalSlider = loadable( () =>  import( './VerticalSlider' ) );
const AddressSearch = loadable( () =>  import( '../components/addressSearch' ) );
const AddressSearchLife = loadable( () =>  import( '../components/addressSearchLife' ) );
const HomePageBlogs = loadable( () => import( '../components/homePageBlogs' ) );
const HomePageNews = loadable( () => import( '../components/homePageNews' ) );
const InsuranceLearnMore = loadable( () => import( '../components/InsuranceLearnMore' ) );
const HomePageTestinomial = loadable( () => import( '../components/HomePageTestinomial' ) );

function BubbleProtects() {

  const [ data, setData ] = useState( {
    banner: {},
    products: [],
    tooltipbox: {}
  } );

  function fireAlert( text, type ){
    Swal.fire( {
      imageUrl: images,
      imageHeight: 151,
      imageWidth: 125,
      title: '',
      text,
      type
    } );
  }

  const strapiData = useStaticQuery(
    graphql`
          query {
            allStrapiArticle(filter: {page_name: {eq: "home_page"}, status: {eq: "Production"}, data_category: {eq: "did_you_know"}}) {
              edges {
                node {
                  body
                  id
                  title
                }
              }
            }
            licenseNumbers: allStrapiPageData(filter: {page_name: {eq: "license_numbers"}}) {
              edges {
                node {
                  data_category
                  column
                  content
                  style_category
                  attribute_1_value
                  attribute_2_value
                  attribute_1_key
                  attribute_2_key
                }
              }
            }
            allStrapiPageData(filter: {page_name: {eq: "partner_logo"}}) {
              edges {
                node {
                  id
                  page_name
                  style_category
                  content
                  attribute_1_key
                  attribute_1_value
                  attribute_2_key
                  attribute_2_value
                  column
                  data_category
                  version
                }
            }
          }
      }`
  );

  const faqArray = [
    {
      question: 'What are the advantages of buying home and life insurance together?',
      answer: 'You need home insurance to protect your home and your personal belongings. Life insurance does the same for your family when you are not around anymore. Buying them together means being prepared for any contingency.'
    },
    {
      question: ' What if I just need one of either life or home insurance?',
      answer: 'Sure, you can buy either one. But we strongly advise you to bundle them together for well-rounded protection for your loved ones.'
    },
    {
      question: 'Can I buy only term policies at Bubble?',
      answer: 'At present you can only buy term policies at Bubble. We help you find the right term policy that you’re comfortable with and one that fits your budget. In future, we shall be offering many more products.'
    },
    {
      question: 'How does Life Insurance fit in with my household\'s other financial responsibilities?',
      answer: 'Our smart guidance platform takes into account your other financial responsibilities, your family circumstances and other factors to help you find the most appropriate coverage and term for your policy. The payout can help your family with mortgage payments, or children\'s college tuition, and they can be financially secure even when you are not there anymore. Plan your expenses right and you will be glad to have bought a policy.'
    },
    {
      question: 'Does everybody really need a medical test to get life insurance?',
      answer: 'No. In fact, Bubble partner carriers provide accelerated, fluidless, no-exam underwriting for close to half the applicants in the 25-45 age group.'
    },
    {
      question: 'What does Home Insurance cover?',
      answer: 'Home insurance helps pay for lost or stolen personal belongings and for repairs or rebuilding your home in case of any damage caused by perils like theft, fire, flood, and so on. It may also cover hotel stay if your home is too damaged to live in.'
    },
    {
      question: 'Is it legally mandatory to buy Home Insurance?',
      answer: 'Legally, it is possible to own a home without home insurance, but your mortgage provider may require that you buy it to protect their financial interests.'
    },
    {
      question: ' Is it too complicated and a lengthy process to buy home or life insurance?',
      answer: 'It used to be a complicated process years ago. Buying has now become simple, fast and easy with the help of technology and user journeys designed by companies, such as Bubble. '
    }
  ];

  useEffect( () => {
    new Rellax( '.rellax', { // <---- Via class name
      speed: 5
    } );
  }, [] );

  useEffect( () => {
    ScrollOut( {
      threshold: .5,
      once: false,
      cssProps: {
        viewportY: true,
        visibleY: true
      }
    } );
  }, [] );


  const [ productType, setProductType ] = useState( 'HOME' );
  const [ showHi, setHi ] = useState( true );
  const [goToQuoteFlow, followQuoteFlow] = useState( false );
  const [ selectAddress, setAddress ] = useState( false );
  const [ onlyLife, setOnlyLife ] = useState( false );
  const [ isActive, setActive ] = useState( [] );
  const [ isValidAddress, setValidAddress ] = useState( false );
  const [ showError, setError ] = useState( false );
  const [ navigateForward, goToNextPage ] = useState( false );
  // const [ userState, setUserState ] = useState( '' );
  // const [ google_address, setUserAddress ] = useState( '' );
  // const [ uid, setUid ]  = useState( '' );
  const [ showApt, setShowAptBool ]  = useState( false );

  const makeActive = ( i ) => {
    const active = isActive.map( ( value, idx ) => {
      if( idx === i ) {
        return !value;
      } else{
        return value;
      }
    } );

    active.forEach( i => {
      if( isActive[i] && !active[i] ){
        const element = document.getElementById( `pr-card${i}` );
        element.classList.add( 'inactive' );
      }
    } );
    if( active[0] && active[2] ) {
      fireAlert( 'Bundling home and life insurance together is coming soon', 'warning' );
      active[i === 0 ? 2 : 0] = false;
    }
    if( active[1] && active[2] ) {
      fireAlert( 'Cannot combine Auto and Life Insurance', 'warning' );
      active[i === 2 ? 1 : 2] = false;
    }

    if( ( active[0] || active[1] ) && isValidAddress ) {
      setShowAptBool( true );
    } else {
      setShowAptBool( false );
    }
    setHi( showHi );
    setActive( active );
    assignProductType( active );
  };

  function assignProductType( active ){
    if( !active[0] && active[2] ){
      setOnlyLife( true );
      setProductType( 'LIFE' );
      return;
    } else if( active[0] && active[1] ) {
      setProductType( 'HOME_AUTO' );
    } else if( active[1] ) {
      setProductType( 'AUTO' );
    } else if( active[0] && active[2] ){
      setProductType( 'HOME_LIFE' );
    } else if( active[0] ){
      setProductType( 'HOME' );
    } else {
      const noneSelected = active.every( bool => !bool );
      if( noneSelected ){
        fireAlert( 'At least one product should be selected' );
        active = [ true, false, false ];
        setActive( active );
        setProductType( 'HOME' );
        if( isValidAddress ){
          setShowAptBool( true );
        }
      }
    }
    setOnlyLife( false );
  }

  function onValidAddress( bool ){
    if( bool ){
      if( ['HOME_AUTO','AUTO' ,'HOME'].includes( productType ) ){
        setShowAptBool( true );
      }
      setError( false );
      setValidAddress( true );
    } else {
      setValidAddress( false );
      goToNextPage( false );
    }
  }

  function lifeAction(){
    setAddress( !selectAddress );
    const zipRef = document.getElementById( 'zipcode' );
    const zip = zipRef.value;
    if( !zip ){
      fireAlert(
        'Please provide a valid zip',
        'warning'
      );
    }
  }

  async function getAction(){
    if( isValidAddress ){
      goToNextPage( true );
    } else {
      setError( true );
    }
  }

  function checkForActive( e, i ){
    const element = document.getElementById( `pr-card${i}` );
    // console.log( `pr-card${i}`, element, i );
    if( !isActive[i] ){
      element.classList.add( 'hover' );
    }
  }

  function removeActiveClass( e, i ){
    const element = document.getElementById( `pr-card${i}` );
    element.classList.remove( 'hover' );
  }

  useEffect( () => {
    fetch( '/protects.json' ).then( ( resp ) => resp.json() )
      .then( body => {
        setData( body );
        const active = [true, false, false];
        setActive( active );
      } );
  }, [] );

  return(
    <Layout>

      <SEO
        title="Digitally bundled home + life insurance | Bubble "
        description="Digitally bundled life and home insurance for homeowners to protect homes &amp; family. Best Bundled Insurance in California. Call (833) 900-2822 to get quote."
        keywords={`home and life Insurance
        home and life insurance quotes
        home life insurance
        house and life insurance
        Best Bundled Insurance
        best bundled insurance in california`}
        canonicalUrl="https://www.getmybubble.com"
        robots={ { 1:'index', 2: 'follow' } }
        schema={[
          JSON.stringify( {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            'name': 'Bubble Insurance',
            'url': 'https://www.getmybubble.com/',
            'logo': 'https://static.wixstatic.com/media/6b54a6_36124e0bf7734ef6b80b7a530a48b8c7~mv2.png/v1/fill/w_160,h_40,al_c,q_85,usm_0.66_1.00_0.01/group-19%25403x_edited.webp',
            'sameAs': [
              'https://www.facebook.com/GetMyBubble',
              'https://www.linkedin.com/company/getmybubble/'
            ]
          } ),
          JSON.stringify(  {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            'url': 'https://www.getmybubble.com/',
            'logo': 'https://www.getmybubble.com/static/buble-077b4498b854b2301e9cbf5a55b69b3d.svg'
          } )
        ]}
      />

      <div className="banner-section">
        <div className="container-fluid">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-12 col-lg-6">
              <div className="banner-details">
                <h2>{data.banner.plain}

                  <span> {data.banner.highlight}</span>
                </h2>

                <p>
                  {data.subBanner}
                </p>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-6 text-center">
              <div className="banner-card">
                <div className="banner-inner-card">
                  <div className="tooltip-outer-box">
                    <img src={productType.length === 4 ? waiting : hey} alt="bannersmallimgcard" className={productType.length === 4 ? 'smallcardimg waiting' : 'smallcardimg hey'}/>

                    { productType.length === 4 &&
                    <div className="tooltip-box">
                      <h4>{data.tooltipbox.headtext}</h4>

                      <p className='tooltip-para'>{data.tooltipbox.paratext}<span>{data.tooltipbox.paratext1}</span>{data.tooltipbox.paratext2}</p>
                    </div>
                    }
                  </div>

                  <h3>{data.productHeading}</h3>

                  <ul className="d-flex align-items-center justify-content-center">
                    {
                      data.products.map( ( product, i ) => (
                        <li id={`pr-card${i}`}
                          onMouseEnter={( e ) => checkForActive( e, i )}
                          onMouseLeave={( e ) => removeActiveClass( e, i ) }
                          onClick={ () => makeActive( i ) }
                          className={ isActive[i] ? 'active smallcard' : 'smallcard'}
                          key={i} >
                          <h4><img className="img-title" src={product.src} alt={product.name} />{product.name}</h4>
                        </li> ) )
                    }
                  </ul>

                </div>

                { productType
                  &&
                  <>
                    <div className={'navsection index ' + `${productType == 'LIFE' ? ' mg23' : ''}`}>
                      {
                        onlyLife ?
                          <div className="col-12 pt-0 pb-0 text-center">
                            <AddressSearchLife selectAddress={selectAddress} onValidAddress={onValidAddress} insuranceType='life' />
                          </div> :
                          <>
                            <div className="col-12 pt-0 pb-0 text-center" >
                              <AddressSearch
                                product={productType}
                                showAptId={showApt}
                                isApp2={true}
                                goToNextPage={navigateForward}
                                followNormalJourney={goToQuoteFlow}
                                onValidAddress={onValidAddress}
                                insuranceType='homeLife'
                                licenseState={strapiData.licenseNumbers.edges} />
                            </div>

                            {showError &&
                            <span className='commonErrorMsg'>
                                  Please enter your Home Address to begin.
                            </span>}
                          </>
                      }
                    </div>

                    <button className='getstart-btn'                       onClick={ onlyLife ? lifeAction : getAction}>Get Started</button>
                  </>
                }
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '0' }}>
            <div className="col-12 text-center">
              <a href="#scrollDiv" className='arrowbox'>
                <img
                  onMouseEnter={( e ) => e.currentTarget.src = 'https://cdn.getmybubble.com/images/corporate-sites/hover-arrow.svg'}
                  onClick={( e ) => {
                    e.preventDefault();
                    e.currentTarget.src = 'https://cdn.getmybubble.com/images/corporate-sites/selecte-arrow.svg';
                    setTimeout( () => {
                      document.getElementById( 'scrollDiv' ).scrollIntoView();
                    }, 100 );
                  }}
                  onMouseLeave={( e ) => e.currentTarget.src = 'https://cdn.getmybubble.com/images/corporate-sites/default-arrow.svg'}
                  src={'https://cdn.getmybubble.com/images/corporate-sites/default-arrow.svg'} alt="downarrow" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper">
        {/* second part div struct */}

        <div id="scrollDiv" className="vertical-slidebox">
          <div className="container common-container-space">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="slide-head hide-mob " data-scroll> See why Home and <br />Life Insurance belong together</h2>
              </div>
            </div>
          </div>

          <VerticalSlider allStrapiArticle={strapiData.allStrapiArticle.edges} />
        </div>

        {/* third part div struct */}

        <InsuranceLearnMore />

        <div className="row">
          <div className="workslide-box-text">
            <h1 className="box-head pb-4 text-center">
            Protect your home and the people you love in minutes.
            </h1>
          </div>
        </div>

        <LandingCalculator />

        {/* fourth part div struct */}

        <div className="workslide-box">
          <div className="container common-container-space">

            <PartnerLogo/>

          </div>
        </div>


        {/* FAQ section */}

        <FaqSection faqs={faqArray} />

        {/* Blog section */}

        <div className="blog-box">
          <div className="container common-container-space">
            {/* style={{"padding":"0 6.9135%"}} */}

            <div className="row">
              <div className="col-12 text-center">
                <h2>Latest from our blog</h2>
              </div>

              <div className="col-12">
                <div className="card-deck blog-section1">
                  { /* Converted To Component To Enable Lazy Loading */ }

                  <HomePageBlogs />
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Testimonial start */}

        <div style={{display: "none"}} className="blog-box testimonialhome">
          <div className="container common-container-space">
            <div className="row">
              <div className="col-12 text-center">
                <h2>Customer testimonials</h2>
              </div>

              <div className="col-12 testinomialbox">
                <div className="row">
                  <div className="col-12">
                    <a href="/testimonial/" className='viewalltxt'>View all</a>
                  </div>
                </div>

                <div className="row flex-nowrap overflow-auto">
                  <HomePageTestinomial />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Testimonial end */}

        <div className="blog-box">
          <div className="container common-container-space">
            {/* style={{"padding":"0 6.9135%"}} */}

            <div className="row">
              <div className="col-12 text-center">
                <h2>Latest from our News</h2>
              </div>

              <div className="col-12">
                <div className="card-deck blog-section1">
                  { /* Converted To Component To Enable Lazy Loading */ }

                  <HomePageNews />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
}

export default BubbleProtects;
